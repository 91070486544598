var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm safetyOrg-org-card",
          attrs: { title: `비상연락체계도` },
        },
        [
          _c(
            "template",
            { slot: "card-button" },
            [_c("q-btn-group", { attrs: { outline: "" } })],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c("div", {
              ref: "org",
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            }),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }